<template lang="pug">
  div(style="overflow: hidden;" qa-id="personal-risk-score")
    div.risk-container(:style="{ maxWidth: riskScoreV2Enabled ? '500px' : 'auto' }")
      div.risk-item.min-value.text-muted.text-right 0
      div.risk-item.chart-container
        RangeChart(
          v-if="rsv2Enabled"
          :score="score"
        )
        GaugeChart(
          v-else
          :score="score"
        )
      div.risk-item.max-value.text-muted.text-left.risk-score-relative 100

    div(v-if="showModal")
      RiskScoreV2OptInModal(
        @hideModal="hideModal"
      )

    div(v-if="rsv2Enabled").more-info.pt-2.pb-3.text-center
      a(href="#" class="cursor-pointer no-outline" @click="openModal($event)") {{ $t('risk_score_modal.score_change') }}

    div.more-info.text-center(:class="{ 'pl-4 pr-4': rsv2Enabled, 'p-4': !rsv2Enabled }")
      a.text-muted(v-if="!rsv2Enabled" :href="learnMoreLink" target="_blank" @click="trackEvent")
        p {{ $t(currentDescription) }}
      p(v-else)
        | {{ $t(currentDescription) }}
        a.text-muted(v-if="rsv2Enabled" :href="learnMoreLink" target="_blank")  {{ $t('common.learn_more') }}
</template>

<script>
import GaugeChart from '@components/dashboard/charts/gauge-chart.vue'
import RangeChart from '@components/dashboard/charts/range-chart.vue'
import RiskScoreV2OptInModal from '@components/dashboard/risk-score-v2-opt-in-modal.vue'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

export default {
  components: { GaugeChart, RangeChart, RiskScoreV2OptInModal },
  props: {
    score: { type: Number, default: 0 },
    riskScoreV2Enabled: { type: Boolean, default: false },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    currentDescription() {
      return this.rsv2Enabled
        ? 'dashboard.vulnerability_score.opt_in_description' // Use rsv2 description if rsv2 is enabled
        : this.description || 'dashboard.vulnerability_score.description' // Use prop description if it exists, otherwise default
    },
    learnMoreLink() {
      return this.rsv2Enabled
        ? 'https://support.knowbe4.com/hc/en-us/articles/33963203317395-Learner-Support-Smart-Risk-Agent-and-Risk-Score-v2'
        : 'https://support.knowbe4.com/hc/en-us/articles/360051072254'
    },
    rsv2Enabled() {
      return (
        this.riskScoreV2Enabled &&
        this.$launchDarkly.get('allow-risk-score-v2-opt-in', false)
      )
    },
  },
  methods: {
    trackEvent() {
      this.$analytics.trackEvent(myDashboard.personalRiskScoreLink)
    },
    hideModal() {
      this.showModal = false
    },
    openModal(event) {
      event.preventDefault()
      this.showModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_risk-score.scss';
</style>
