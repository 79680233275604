<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    :class="{ branded: branded }"
    class="svg"
    v-html="branded ? brandedSvg : parsedSvg" />
  <!--eslint-enable-->
</template>

<script>
export default {
  props: {
    branded: { type: Boolean, required: false, default: false },
    svg: { type: String, required: true },
    svgClass: { type: String, required: false, default: '' },
    id: { type: String, required: true, default: 'inline-svg' },
    title: { type: String, required: false, default: '' },
  },
  computed: {
    brandedSvg() {
      return this.processSvg(true)
    },
    parsedSvg() {
      return this.processSvg(false)
    },
  },
  methods: {
    processSvg(isBranded) {
      if (!this.title && !isBranded) return this.svg
      const xmlDoc = new DOMParser().parseFromString(this.svg, 'text/xml')
      const titleElement = xmlDoc.createElement('title')
      try {
        titleElement.setAttribute('id', 'title')
        titleElement.textContent = `${this.title}`
        xmlDoc.firstChild.insertBefore(
          titleElement,
          xmlDoc.firstChild.firstChild
        )
        if (isBranded) {
          xmlDoc.firstChild.setAttribute('class', `branded ${this.svgClass}`)
          xmlDoc.firstChild.setAttribute('id', `svg-${this.id}`)
          xmlDoc.firstChild.setAttribute('role', 'img')
          xmlDoc.firstChild.setAttribute('alt', '')
          if (this.title) {
            xmlDoc.firstChild.setAttribute('aria-labelledby', 'title')
          } else {
            xmlDoc.firstChild.setAttribute('aria-label', `svg-${this.id}`)
          }
        } else {
          xmlDoc.firstChild.setAttribute('aria-labelledby', 'title')
        }
        return new XMLSerializer().serializeToString(xmlDoc.documentElement)
      } catch (e) {
        return null
      }
    },
  },
}
</script>
