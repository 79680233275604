<template lang="pug">
  b-modal(
    id="risk-score-v2-opt-in-modal"
    v-model="showModal"
    lazy
    size="lg"
    role="document"
    @hidden="hideModal")
    template(slot="modal-header")
      h5.m-0 {{ $t('risk_score_modal.title') }}
      b-button.m-0.p-0.close(type="button" aria-label="Close" @click="hideModal()")
        fa.fa-xs(icon='times' size="small")
    div.container.text-center {{ $t('risk_score_modal.risk_score_change') }}
    template(slot="modal-footer")
      b-button(
        id="confirm-button"
        class="btn-primary"
        @click="confirm()") {{ $t('common.learn_more') }}
      b-button(
        id="cancel-button"
        class="btn-secondary"
        @click="close()") {{ $t('common.close') }}
</template>

<script>
import LanguageSelectionOptionsList from '@components/global/language-selection-options-list'

export default {
  components: { LanguageSelectionOptionsList },
  data() {
    return {
      showModal: true,
    }
  },
  methods: {
    hideModal() {
      this.$emit('hideModal')
    },
    close() {
      this.showModal = false
    },
    confirm() {
      window.open(
        'https://support.knowbe4.com/hc/en-us/articles/33963203317395-Learner-Support-Smart-Risk-Agent-and-Risk-Score-v2',
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss">
#risk-score-v2-opt-in-modal .modal-footer {
  padding: 0.25rem !important;
}
</style>
