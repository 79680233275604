

































import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomCheckbox from '@components/global/custom-checkbox.vue'
import LanguageSelectionDropdown from '@components/enrollments/language-selection-dropdown.vue'
import SessionStore from '@state/modules/session'
import { getLocale } from '@src/locale'

// types
import Course from '@src/models/course'
import Policy from '@src/models/policy'

@Component({ components: { CustomCheckbox, LanguageSelectionDropdown } })
export default class EnrollmentActions extends Vue {
  @Prop({ required: true }) enrollment!: Course | Policy
  @Prop({ required: true }) selectedLanguage
  @Prop({ default: false }) firstEnrollment!: boolean
  @Prop({ default: false }) dropup!: boolean

  isMobile: boolean = (this as any).$isMobile()

  get enrollmentTitle() {
    return this.selectedLanguage
      ? this.selectedLanguage.title
      : this.enrollment?.title
  }

  get completedAssessment() {
    return (
      this.enrollment?.enrollmentType === 'Assessment' &&
      this.enrollment?.isComplete
    )
  }

  get startButtonText() {
    if (this.enrollment?.hasNotEnrolled) {
      return this.$t('enrollment.actions.enroll')
    }
    switch (this.enrollment?.state) {
      case 'do_policy':
      case 'completed':
        return this.$t('enrollment.actions.review')
      case 'in_progress':
        return this.$t('enrollment.actions.resume')
      default:
        return this.$t('enrollment.actions.start')
    }
  }

  get startButtonStyle() {
    return this.enrollment?.state === 'do_policy' || this.enrollment?.isComplete
      ? 'secondary'
      : 'primary'
  }

  get languageOptions() {
    return this.enrollment?.languageOptions
  }

  get acceptUrlDisabled() {
    return (
      this.enrollment?.isComplete ||
      (!!this.enrollment?.duration && !this.enrollment?.isInProgress)
    )
  }

  get acceptText() {
    return this.selectedLanguage.acceptanceText
  }

  get isUrlPolicy() {
    return this.enrollment?.policyType === 'URL'
  }

  get optionalTrainingEnabled() {
    return !!SessionStore.optionalTrainingEnabled
  }

  startCourse() {
    this.$emit('startCourse')
  }

  selectLanguage(lang) {
    this.$emit('selectLanguage', lang)
  }

  rtl() {
    return getLocale()?.rtl
  }
}
