




































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import DashboardTile from '@components/dashboard/dashboard-tile.vue'
import CompletedTraining from '@components/dashboard/completed-training.vue'
import AllTrainingComplete from '@components/dashboard/all-training-complete.vue'
import NoTrainingAssigned from '@components/dashboard/no-training-assigned.vue'
import BadgesEarned from '@components/dashboard/badges-earned.vue'
import KrsAvailable from '@components/dashboard/krs-available.vue'
import LeaderboardPosition from '@components/dashboard/leaderboard-position.vue'
import PhishingTests from '@components/dashboard/phishing-tests.vue'
import RiskScore from '@components/dashboard/risk-score.vue'
import CampaignTable from '@components/dashboard/campaign-table.vue'
import Messages from '@components/dashboard/messages.vue'
import EnrollmentStore from '@state/modules/enrollments'
import SessionStore from '@state/modules/session'
import UnifiedLeaderboardStore from '@state/modules/unified_leaderboard'
import { LeaderboardTypes } from '@/src/state/api/graphql'
import { ILeaderboardIndividualByAccount } from '@/src/models/unified-leaderboard'
import OptionalEnrollment from '@/src/models/optional-enrollment'

@Component({
  components: {
    DashboardTile,
    CompletedTraining,
    AllTrainingComplete,
    BadgesEarned,
    LeaderboardPosition,
    PhishingTests,
    RiskScore,
    CampaignTable,
    NoTrainingAssigned,
    Messages,
    KrsAvailable,
  },
  page: {
    title: 'Dashboard',
    meta: [{ name: 'description', content: 'My Dashboard' }],
  },
})
export default class MyDashboard extends Vue {
  fields: { [key: string]: any }[] = []
  fetchLeaderboardIntervalId: number | undefined = undefined

  mounted() {
    if (!SessionStore.myDashboardEnabled) {
      this.$router.push({ path: '/dashboard' })
    }
    this.fields = [
      {
        label: this.$t('dashboard.training_table.assignment_name'),
        key: 'title',
      },
      {
        label: this.$t('dashboard.training_table.type'),
        key: 'enrollmentType',
      },
      { label: this.$t('dashboard.training_table.status'), key: 'status' },
      { label: this.$t('dashboard.training_table.due'), key: 'expiresAt' },
    ]

    if (this.loadingLeaderboardLeadersFromBackEnd) {
      this.fetchLeaderboardIntervalId = window.setInterval(
        this.fetchLeaderboard,
        3000
      )
    }
  }

  destroyed() {
    if (this.fetchLeaderboardIntervalId) {
      window.clearInterval(this.fetchLeaderboardIntervalId)
    }
  }

  get loadingLeaderboardLeadersFromBackEnd() {
    const leaderboard = UnifiedLeaderboardStore.leaderboards?.find(
      l => l.leaderboardType === LeaderboardTypes.GroupLeaderboard
    )

    if (leaderboard !== null && leaderboard !== undefined) {
      return leaderboard.leaders.length === 0
    }

    return false
  }

  async fetchLeaderboard() {
    await UnifiedLeaderboardStore.fetchLeaderboard()

    if (!this.loadingLeaderboardLeadersFromBackEnd) {
      window.clearInterval(this.fetchLeaderboardIntervalId)
    }
  }

  get myDashboardEnabled() {
    return SessionStore.myDashboardEnabled
  }

  get riskScoreV2Enabled() {
    return SessionStore.riskScoreV2Enabled
  }

  get amountOfActiveTiles() {
    return [
      this.badgesEnabled,
      this.showLeaderboardPosition,
      this.phishingStatistics,
      this.riskStatistics,
      this.knowledgeRefreshersEnabled,
    ].filter(item => item).length
  }

  get additionalTileSize() {
    if (this.amountOfActiveTiles % 3 === 0) {
      return 4
    }
    return 6
  }

  get gamificationEnabled() {
    return this.badgesEnabled || this.hasPreviewLeaderboard
  }

  get noTrainingAssigned() {
    return this.enrollmentsStatistics?.total === 0
  }

  get allTrainingComplete() {
    return (
      this.enrollmentsStatistics?.complete === this.enrollmentsStatistics?.total
    )
  }

  get badgesEnabled() {
    return SessionStore.currentUser?.gamification?.badgesEnabled
  }

  get showLeaderboardPosition() {
    return this.hasPreviewLeaderboard
  }

  get hasPreviewLeaderboard() {
    return !!this.previewLeaderboard
  }

  get leaderboardType() {
    return this.previewLeaderboard?.leaderboardType
  }

  get leaderboardParticipantCount() {
    return (
      this.previewLeaderboard?.leaderboardSize ||
      this.previewLeaderboard?.leaders.filter(l => l.score > 0).length
    )
  }

  get currentUserLeaderboardScore() {
    const userIndex = this.leaderboardIndex

    if (
      userIndex === null ||
      userIndex < 0 ||
      this.leaderboard?.leaders.length === 0
    )
      return null

    return this.leaderboard?.leaders[userIndex].score
  }

  get leaderboardRank() {
    const userIndex = this.leaderboardIndex

    if (userIndex === null || userIndex < 0) return null

    if (
      this.leaderboard?.leaders &&
      this.leaderboardType === LeaderboardTypes.IndividualLeaderboard
    ) {
      const user = this.leaderboard?.leaders[
        userIndex
      ] as ILeaderboardIndividualByAccount
      return user.rank
    }

    if (userIndex >= 0) {
      return userIndex + 1
    }

    return null
  }

  get leaderboard() {
    return UnifiedLeaderboardStore.leaderboards?.find(l =>
      l.leaders.some(u => u.includesCurrentUser)
    )
  }

  get previewLeaderboard() {
    // The preview leaderboard is the first leaderboard where the user is present
    return UnifiedLeaderboardStore.leaderboards?.find(l =>
      l.leaders.some(u => u.includesCurrentUser)
    )
  }

  get leaderboardIndex() {
    if (!this.previewLeaderboard) {
      return null
    }

    return this.previewLeaderboard.leaders.findIndex(
      item => item.includesCurrentUser
    )
  }

  get userStatistics() {
    return SessionStore.statistics
  }

  get enrollmentsStatistics() {
    return this.userStatistics?.enrollmentsStatistics
  }

  get phishingStatistics() {
    return this.userStatistics?.phishingStatistics
  }

  get riskStatistics() {
    return this.userStatistics?.riskStatistics
  }

  get badgesEarned() {
    return SessionStore.currentUser?.gamification?.badgesEarned?.length
  }

  get knowledgeRefreshersEnabled(): boolean {
    return this.$launchDarkly.get('knowledge-refreshers', false)
  }

  get knowledgeRefreshers() {
    if (!this.knowledgeRefreshersEnabled) return []
    return EnrollmentStore.knowledgeRefresherEnrollments.filter(
      e => e.state !== 'completed'
    ) as OptionalEnrollment[]
  }

  get motionReduced() {
    return SessionStore.motionReduced
  }

  get enrollmentItemsToDisplay() {
    return EnrollmentStore.lastFiveEnrollments
  }

  get enrollmentItems() {
    return this.enrollmentItemsToDisplay.map(item => {
      const { state, enrollmentId, expiresAt } = item
      return {
        id: enrollmentId,
        title: item.localizedTitle,
        enrollmentType: this.$t(item.contentType.translationKey),
        status: state,
        createdAt: item.createdAt,
        expiresAt: expiresAt || null,
      }
    })
  }

  get messagesEnabled() {
    return SessionStore.messagesEnabled
  }
}
