<template lang="pug">
  svg(
    version="1.1"
    width="100%"
    height="100%"
    :viewBox="`30 0 ${width} ${height}`"
    xmlns="http://www.w3.org/2000/svg"
  )
    circle(
      :cx="leftCirclePos"
      :cy="graphTopPosition + circleRadius"
      fill="#548C2F"
      :r="circleRadius"
    )
    circle(
      :cx="rightCirclePos"
      :cy="graphTopPosition + circleRadius"
      fill="#E21E03"
      :r="circleRadius"
    )
    rect(
      :x="leftRectX"
      :y="graphTopPosition"
      :width="rectWidth1"
      :height="graphHeight"
      fill="#548C2F"
    )
    rect(
      :x="rectX2"
      :y="graphTopPosition"
      :width="rectWidth2"
      :height="graphHeight"
      fill="#FFBF00"
    )
    rect(
      :x="rectX3"
      :y="graphTopPosition"
      :width="rectWidth3"
      :height="graphHeight"
      fill="#F26721"
    )
    rect(
      :x="rightRectX"
      :y="graphTopPosition"
      :width="rectWidth4"
      :height="graphHeight"
      fill="#E21E03"
    )
    polygon(
      :points="`${pointerTopX},${pointerTopY} ${pointerLeftX},${pointerBottomY} ${pointerRightX},${pointerBottomY}`"
      fill="black"
    )
    text(
      :x="pointerTextX"
      :y="valueTextY"
      font-size="28"
      font-family="'Open Sans', sans-serif"
      text-anchor="middle"
      fill="#757578"
    ) {{ score.toFixed(1) }}
</template>

<script>
export default {
  props: { score: { type: Number, default: 0 } },
  computed: {
    height() {
      return 190
    },
    graphHeight() {
      return 60
    },
    width() {
      return 900
    },
    circleRadius() {
      return this.graphHeight / 2
    },
    graphTopPosition() {
      return 45
    },
    leftCirclePos() {
      return this.circleRadius + 30
    },
    rightCirclePos() {
      return 897
    },
    graphWidth() {
      return this.rightCirclePos - this.leftCirclePos
    },
    space() {
      return 4.5 // Space between rectangles.
    },
    rectWidth1() {
      return 111 // First rectangle width.
    },
    rectWidth2() {
      return 217.5 // Second rectangle width.
    },
    rectWidth3() {
      return 217.5 // Third rectangle width.
    },
    rectWidth4() {
      return 275.5
    },
    leftRectX() {
      return this.leftCirclePos // Left rectangle starts at the left circle.
    },
    rectX2() {
      return this.leftRectX + this.rectWidth1 + this.space // Second rectangle follows the first.
    },
    rectX3() {
      return this.rectX2 + this.rectWidth2 + this.space // Third rectangle follows the second.
    },
    rightRectX() {
      return this.rightCirclePos - this.rectWidth4 // Adjust right rect position so it touches the right circle.
    },
    pointerHalfBaseWidth() {
      return 10
    },
    pointerTopX() {
      return (this.graphWidth / 100) * this.score + this.circleRadius + 20
    },
    pointerLeftX() {
      return this.pointerTopX - this.pointerHalfBaseWidth
    },
    pointerRightX() {
      return this.pointerTopX + this.pointerHalfBaseWidth
    },
    pointerTextX() {
      return this.pointerLeftX + this.pointerHalfBaseWidth
    },
    pointerTopY() {
      return this.graphTopPosition + 60
    },
    pointerBottomY() {
      return this.graphTopPosition + 75
    },
    valueTextY() {
      return this.graphTopPosition + 103
    },
  },
}
</script>
