























import Vue from 'vue'
import { Component, Inject } from 'vue-property-decorator'
import { filterXSS, getDefaultWhiteList } from 'xss'

@Component({})
export default class KrBadgesBanner extends Vue {
  @Inject('isRTL') rtl: boolean | undefined

  get badgesEnabled() {
    // disabling badge banner for now until we have Knowledge Refresher badges
    // return SessionStore.currentUser?.gamification?.badgesEnabled
    return false
  }

  get badgeTitle() {
    return this.badgesEnabled
      ? this.$t('knowledge_refreshers.earnBadgesCopy')
      : this.$t('knowledge_refreshers.testYourKnowledge')
  }

  sanitize(content) {
    return filterXSS(content, {
      whiteList: getDefaultWhiteList(),
    })
  }
}
